@import 'https://fonts.googleapis.com/css?family=Karla:400,400italic,700,700italic';
@import 'https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic';
@import 'https://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic,900,900italic';
@import 'bootstrap';
@import 'font-awesome';
@import 'icomoon';
@import 'flexslider';
@import 'owl.carousel';
@import 'shortcodes';
@import 'revolution-slider';
@import 'magnific-popup';
@import 'simple-line-icons';
@import 'justifiedGallery';
@import 'style';
@import 'responsive';
@import 'colors/color1';
@import 'animate';
