/**
  * Name: HNK
  * Version: 1.0
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/



/**
  * Reset
  * Repeatable Patterns
  * Top
  * Header
  * Mobile navigation
  * Page title
  * Blog
  * Widget
  * Boxed
  * Switcher
  * GoTop Button
  * Footer
  * Social
  * Flat search
  * Search results
  * 404
  * Parallax
  * Revolution Slider
  * Preload
*/

/* Reset
-------------------------------------------------------------- */
	html {
		overflow-y: scroll;
		-webkit-text-size-adjust: 100%;
		   -ms-text-size-adjust: 100%;
	}

	body {
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-webkit-text-size-adjust: 100%;
		   -ms-text-size-adjust: 100%;
	}

	a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, caption, canvas, center, cite, code,
	dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, form, footer, header, hgroup, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav,object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, tt, table, tbody, textarea, tfoot, thead, time, tr, th, td, u, ul, var, video  {
		font-family: inherit;
		font-size: 100%;
		font-weight: inherit;
		font-style: inherit;
		vertical-align: baseline;
		margin: 0;
		padding: 0;
		border: 0;
		outline: 0;
		background: transparent;
	}

	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		-webkit-hyphens: none;
		  -moz-hyphens: none;
		   -ms-hyphens: none;
		       hyphens: none;
		        quotes: none;
	}

	figure {
		margin: 0;
	}

	:focus {
		outline: 0;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	img {
		border: 0;
		-ms-interpolation-mode: bicubic;
		vertical-align: middle;
	}

	legend {
		white-space: normal;
	}

	button,
	input,
	select,
	textarea {
		font-size: 100%;
		margin: 0;
		max-width: 100%;
		vertical-align: baseline;
		-webkit-box-sizing: border-box;
		  -moz-box-sizing: border-box;
		       box-sizing: border-box;
	}

	button,
	input {
		line-height: normal;
	}

	input,
	textarea {
		background-image: -webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0)); /* Removing the inner shadow, rounded corners on iOS inputs */
	}

	button,
	input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		line-height: 1;
		cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
		-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
		border: none;
	}

	input[type="checkbox"],
	input[type="radio"] {
		padding: 0; /* Addresses excess padding in IE8/9 */
	}

	input[type="search"] {
		-webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
	}

	input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
		-webkit-appearance: none;
	}

	button::-moz-focus-inner,
	input::-moz-focus-inner { /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
		border: 0;
		padding: 0;
	}

	*,
	*:before,
	*:after {
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
		        box-sizing: border-box;
	}

	/* Font */
	@font-face {
	  font-family: 'Notera_PersonalUseOnly';
	  src: url('../fonts/Notera_PersonalUseOnly.eot'); /* IE9 Compat Modes */
	  src: url('../fonts/Notera_PersonalUseOnly.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	    url('../fonts/Notera_PersonalUseOnly.woff2') format('woff2'), /* Modern Browsers */
	    url('../fonts/NoteraPersonalUseOnly.woff') format('woff'), /* Modern Browsers */
	    url('../fonts/NoteraPersonalUseOnly.ttf') format('truetype'), /* Safari, Android, iOS */
	    url('../fonts/NoteraPersonalUseOnly.svg') format('svg'); /* Legacy iOS */  font-weight: normal;
	  font-style: normal;
	}

/* Repeatable Patterns
-------------------------------------------------------------- */
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

body {
	font: 15px/28px "Karla", sans-serif;
	font-weight: 400;
	background-color: #fff;
	color: #777777;
	line-height: 1.8;
}

a {
	text-decoration: none;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		 -o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
}

span {
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		 -o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
	color: #106a36;
	text-decoration: none;
	outline: 0;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		 -o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
}

ul, ol {
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		 -o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
}

.img-left {
	margin: -10px 50px 0 0 !important;
	float: left;
}

.img-right {
	margin: 0 0 0 50px !important;
	float: right;
}

b, strong {
	font-weight: 900;
}

.entry-page p {
	margin-bottom: 25px;
}

h1 { font: 40px/42px "Playfair Display", sans-serif;}
h2 { font: 30px/32px "Playfair Display", sans-serif;}
h3 { font: 24px/30px "Playfair Display", sans-serif;}
h4 { font: 20px/25px "Playfair Display", sans-serif;}
h5 { font: 18px/20px "Playfair Display", sans-serif;}
h6 { font: 16px/18px "Playfair Display", sans-serif;}
h1, h2, h3, h4, h5, h6 { color: #333333; }

button {
	border: none;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    font-size: 14px;
    font-family: "Karla", sans-serif;
    font-weight: 700;
    color: #fff;
    background: transparent;
    text-transform: uppercase;
    padding: 24px 120px 21px 124px;
    letter-spacing: 1.2px;
    -webkit-transition: all 0.3s ease 0s;
       -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
         -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
	background: rgba(0, 0, 0, 0.05);
    position: relative;
    border: none;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.06);
    height: 45px;
    max-width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
            border-radius: 2px;
}

select {
    border: 1px solid rgba(0, 0, 0, 0.06);
    background-color: rgba(0, 0, 0, 0.05);
    height: 45px;
    padding: 10px;
    line-height: 100%;
    outline: 0;
    max-width: 100%;
    background-image: url(/assets/images/icon/caret.png);
    background-position: 96% center;
    background-repeat: no-repeat;
    position: relative;
    text-indent: 0.01px;
    text-overflow: '';
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

input[type="text"],
input[type="email"] {
    width: 100%;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
	border: 1px solid #106a36;
	background-color: #fff;
	-webkit-box-shadow: none;
	   -moz-box-shadow: none;
			box-shadow: none;
}

input[type="submit"],
button[type="submit"], .button {
    color: #ffffff;
    background-image: none;
    height: 45px;
    line-height: 45px;
    padding: 0 40px;
    cursor: pointer;
    white-space: nowrap;
    background-color: #106a36;
    border: none;
    display: inline-block;
    font-weight: bold;
    text-transform: capitalize;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
            border-radius: 2px;
}

input[type="submit"]:hover,
button[type="submit"]:hover, .button:hover {
    background: #333333;
    color: #ffffff;
}

textarea {
	width: 100%;
	height: 150px;
	padding: 12px 17px;
}

input[type="checkbox"] {
	display: inline;
}

textarea:-moz-placeholder,
textarea::-moz-placeholder,
input:-moz-placeholder,
input::-moz-placeholder {
	color: #999999;
	opacity: 1;
}

input:-ms-input-placeholder {
	color: #999999;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
	color: #999999;
	opacity: 1;
}

@-webkit-keyframes pop-scale { 0% { -webkit-transform: scale(0.7) } 100% { -webkit-transform: scale(1) } }
@-moz-keyframes pop-scale { 0% { -moz-transform: scale(0.7) } 100% { -moz-transform: scale(1) } }
@keyframes pop-scale { 0% { transform: scale(0.7) } 100% { transform: scale(1) } }

/* bootstrap resetting elements */
.btn {
	background-image: none;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.dropdown-menu,
.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
	-webkit-appearance: none;
	text-shadow: none;
	-webkit-box-shadow: none;
	   -moz-box-shadow: none;
	     -o-box-shadow: none;
	        box-shadow: none;
	color: #999999;
}

input[type="submit"] {
	-webkit-appearance: none;
	text-shadow: none;
	-webkit-box-shadow: none;
	   -moz-box-shadow: none;
	     -o-box-shadow: none;
	        box-shadow: none;
	color: #ffffff;
}

.pad-top0px { padding-top: 0; }

.pad-bottom0px { padding-bottom: 0; }
.pad-bottom20px { padding-bottom: 20px; }
.pad-bottom40px { padding-bottom: 40px; }
.pad-bottom100px { padding-bottom: 100px; }

.mag-top-50px { margin-top: -50px; }

.blog-single .entry p {
	margin-bottom: 30px;
}

@media (min-width: 1200px) {
  	.container {
    	width: 1140px;
  	}
}

/* Top
---------------------------------------------------------------*/
.top {
	background-color: #106a36;
}

/* Infomation top */
.flat-address ul {
	margin: 0 0;
	padding: 0;
}

.flat-address ul > li {
	display: inline-block;
	position: relative;
	margin-left: 40px;
	padding-left: 18px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.flat-address ul > li:first-child {
	margin-left: 0;
}

.flat-address ul > li:before {
	position: absolute;
	left: 0;
	top: 5px;
	font-family: FontAwesome;
	content: "\f041";
	color: #fff;
	font-size: 15px;
}

.flat-address ul > li.address {
	font-size: 15px;
	font-family: "Karla", sans-serif;
	color: #fff;
}

/* Navigator top */
.top .top-navigator {
   float: right;
}

.top .top-navigator > ul > li {
	display: inline-block;
	padding: 5px 0;
	padding-left: 10px;
	position: relative;
}

.top .top-navigator > ul > li.service {
	padding-left: 16px;
}

.top .top-navigator > ul > li.service:after {
	border-top: 5px solid rgba(0, 0, 0, 0.2);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    height: 0;
    width: 0;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;
}

.top .top-navigator ul > li > a {
	color: #fff;
}

.top .top-navigator ul > li > a:hover {
	color: #333333;
}

.top .top-navigator > ul > li > ul {
   position: absolute;
   left: 0;
   top: 100%;
   width: 250px;
   background-color: #222222;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       filter: alpha(opacity=0);
      opacity: 0;
   visibility: hidden;
   z-index: 9999999;
   -webkit-transform: translate(0px, 10px);
	  -moz-transform: translate(0px, -10px);
	   -ms-transform: translate(0px, -10px);
		-o-transform: translate(0px, -10px);
		   transform: translate(0px, -10px);
    -webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.top .top-navigator > ul > li:hover > ul {
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
       filter: alpha(opacity=100);
      opacity: 1;
   visibility: visible;
   -webkit-transform: translate(0, 0);
	   -moz-transform: translate(0, 0);
	    -ms-transform: translate(0, 0);
	     -o-transform: translate(0, 0);
	        transform: translate(0, 0);
}

.top .top-navigator > ul > li > ul li:first-child {
   border-top: none;
}

.top .top-navigator > ul > li > ul li a {
   display: block;
   padding: 5px 30px;
   line-height: 29px;
   color: #ccc;
}

.top .top-navigator > ul > li > ul li a:hover {
	background-color: #333333;
}

.top .top-navigator > ul > li > ul li:hover a {
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
}

/* Site brand */
.site-brand {
	background-color: #fff;
	color: #777;
}

.site-brand .logo {
	float: left;
	margin-top: 15px;
	margin-bottom: 10px;
	img {
		height: 75px;
	}
}

.site-brand .header-widgets {
	float: right;
}

.site-brand .header-widgets .widget {
	display: inline-block;
    text-align: left;
    margin-bottom: 0;
    vertical-align: top;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 38px;
    margin-right: 38px;
    color: #777;
}

.site-brand .header-widgets #text-3 {
	border-right: 0;
	padding-right: 0;
    margin-right: 0;
}

.site-brand .header-widgets .widget i {
    font-size: 32px;
    vertical-align: middle;
    margin-right: 15px;
    color: #106a36;
}

.site-brand .header-widgets .widget .info-icon .content {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.4;
}

/* Header widgets */
.header-widgets {
	display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding: 30px 0;
}

.header-widgets .widget .info-icon .content span {
	color: #333333;
}

.button {
	color: #ffffff;
    background-color: #106a36;
    height: 45px;
    line-height: 45px;
    padding: 0 40px;
    cursor: pointer;
    white-space: nowrap;
    border: none;
    display: inline-block;
    font-weight: bold;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
            border-radius: 2px;
}

.button:hover {
	background-color: #333333;
	color: #fff;
}

/* Header
-------------------------------------------------------------- */
.header {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	background: rgba(255, 255, 255, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	z-index: 999999;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.header .header-wrap {
	max-width: 1920px;
	margin: 0 auto;
}

.header .header-wrap .logo {
	width: 186px;
	height: 45px;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		 -o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
	float: left;
	margin: 38px 300px 0 60px;
}

.header .header-wrap .show-search {
	float: right;
}

.header .header-wrap .show-search {
	line-height: 120px;
	padding-right: 38px;
}

/* Header Fix */
#header.downscrolled {
	position: fixed;
	top: 0;
	width: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       opacity: 0;
        filter: alpha(opacity=0);
    top: -60px;
    max-width: 1920px;
	margin: 0 auto;
    z-index: 999999;
    -webkit-transition: all 0.5s ease-in-out;
       -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
         -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
}

#header.upscrolled {
    opacity: 1;
    top: 0;
    max-width: 1920px;
	margin: 0 auto;
	background: #fff;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.04);
	z-index: 999999;
}

#header.upscrolled #mainnav > ul > li > a {
	color: #777;
}

#header.upscrolled #mainnav > ul > li > a:hover {
	color: #106a36;
	text-decoration: underline;
}

#header.upscrolled #mainnav > ul > li.home > a {
	border-right: 1px solid #777;
	color: #777;
}

#header.upscrolled .menu-extra > li > a {
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

#header.upscrolled #mainnav .social-links a {
	background-color: rgba(0, 0, 0, 0.1);
	color: rgba(0, 0, 0, 0.3);
}

#header.upscrolled #mainnav .social-links a:hover {
	background-color: #106a36;
	color: #333;
}

#header.upscrolled #mainnav > ul > li.has-children > a:after {
	border-top-color: rgba(0, 0, 0, 0.1);
}

.home-boxed #header.downscrolled,
.home-boxed #header.upscrolled {
	width: 100%;
}

/* navigation */
.nav-wrap {
	position: relative;
}

#mainnav ul {
   list-style: none;
   float: left;
}

#mainnav ul li {
   position: relative;
}

#mainnav > ul > li {
   display: inline-block;
}

#mainnav > ul > li > a {
    position: relative;
    display: block;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
	text-transform: capitalize;
    color: #fff;
    text-decoration: none;
    line-height: 80px;
    padding: 0 20px 0 20px;
    outline: none;
}

#mainnav > ul > li.home > a {
	text-indent: -9999px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    padding-right: 45px;
    margin-right: 15px;
}

#mainnav > ul > li.home > a:before {
	font-family: "simple-line-icons";
    content: "\e069";
    text-indent: 0;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 32px;
}

/* Has children */
#mainnav > ul > li.has-children > a:after {
    border-top: 5px solid #777;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    bottom: 19px;
    left: 50%;
    margin-left: -5px;
}

/* submenu */
#mainnav ul.submenu {
    position: absolute;
    width: 250px;
    background: #222222;
    padding: 10px 0;
    margin: 0;
    visibility: hidden;
    display: none\9;
    left: -10px;
    top: 150%;
    -webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
    -webkit-opacity: 0;
    -khtml-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    filter: alpha(opacity=0);
}

/* right sub-menu */
#mainnav ul.right-sub-menu {
    left: auto;
    right: 0;
}

#mainnav ul li:hover > ul.submenu {
	top: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
       filter: alpha(opacity=100);
      opacity: 1;
    visibility: visible;
}

#mainnav ul li ul li {
   margin-left: 0;
}

#mainnav ul.submenu li ul {
   position: absolute;
   left: 360px;
   top: 0 !important;
}

#mainnav ul.submenu > li.sub-parent:after {
	content: "\f105";
   	font-family: "FontAwesome";
   	font-size: 14px;
   	position: absolute;
   	right: 25px;
   	top: 11px;
   	color: #999999;
    -webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

#mainnav ul.submenu > li > a {
	color: #a6a6a6;
    padding: 5px 30px;
    font-size: 14px;
    display: block;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

#mainnav ul.submenu > li > a:hover {
	background-color: #000000;
}

#mainnav ul.submenu > li.sub-parent:hover::after {
	right: 18px;
}

#mainnav > ul > li > a:hover,
#mainnav > ul > li.home > a {
   color: #fff;
}

#mainnav > ul > li > a:hover {
	background-color: rgba(255,255,255,0.2);
}

#mainnav > ul > li.home > a:hover {
	background: transparent;
}

/* Mega Menu */
#mainnav .mega-menu {
    position: absolute;
    top: 120px;
    left: 0;
    margin: 0 -15px;
    text-align: left;
    z-index: 9999;
    background-color: #222222;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   	  filter: alpha(opacity=0);
     opacity: 0;
    visibility: hidden;
   -webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

#mainnav .mega-menu.three-colums {
	width: 750px;
}

#mainnav .mega-menu.two-columns {
	width: 500px;
}

#mainnav .mega-menu.two-columns .menu-item {
	width: 50%;
}

#mainnav .mega-menu.three-colums .menu-item {
	width: 33.333333%;
}

#mainnav .mega-menu .menu-item {
	float: left;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

#mainnav .mega-menu .menu-item > a {
	color: #fff;
	padding: 10px 30px;
	line-height: 45px;
	font-size: 14px;
}

#mainnav .mega-menu .menu-item ul {
	float: none;
}

#mainnav ul li.has-mega-menu:hover > .mega-menu {
	top: 80px;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
       filter: alpha(opacity=100);
      opacity: 1;
   visibility: visible;
}

.mega-menu ul li a {
	display: block;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #999999;
    text-decoration: none;
    padding: 5px 30px;
}

.mega-menu ul li a:hover {
	background-color: #000000;
}

/* Mobile navigation
---------------------------------------- */
#mainnav-mobi {
   	display: block;
   	margin: 0 auto;
   	width: 100%;
   	position: absolute;
   	background-color: #222222;
   	z-index: 99999;
}

#mainnav-mobi ul {
   	display: block;
   	list-style: none;
   	margin: 0;
   	padding: 0;
}

#mainnav-mobi ul li {
   	margin:0;
   	position: relative;
   	text-align: left;
   	border-top: 1px solid #333333;
   	cursor: pointer
}

#mainnav-mobi ul > li > a {
   	text-decoration: none;
   	height: 50px;
   	line-height: 50px;
   	padding: 0 50px;
   	color: #fff;
   	display: block;
}

#mainnav-mobi ul > li > a:hover {
	background-color: #000;
}

#mainnav-mobi ul.sub-menu {
   	top: 100%;
   	left: 0;
   	z-index: 2000;
   	position: relative;
   	background-color: #333333;
}

#mainnav-mobi > ul > li > ul > li,
#mainnav-mobi > ul > li > ul > li > ul > li {
   	position: relative;
   	border-top: 1px solid #333333;
}

#mainnav-mobi > ul > li > ul > li > ul > li a {
   	padding-left: 70px !important
}

#mainnav-mobi ul.sub-menu > li > a {
   	display: block;
   	text-decoration: none;
   	padding: 0 60px;
   	border-top-color: rgba(255,255,255,.1);
	-webkit-transition: all 0.2s ease-out;
	   -moz-transition: all 0.2s ease-out;
	     -o-transition: all 0.2s ease-out;
	        transition: all 0.2s ease-out;
}

#mainnav-mobi > ul > li > ul > li:first-child a {
	border-top: none;
}

#mainnav-mobi ul.sub-menu > li > a:hover,
#mainnav-mobi > ul > li > ul > li.active > a {
	color: #fff;
}

#mainnav-mobi > ul > li.has-mega-menu .sub-menu.mega-menu .menu-item > a {
	padding: 0 40px;
	font-weight: 600;
	font-size: 16px;
	text-transform: uppercase;
	color: #106a36;
}

#mainnav-mobi .search-box,
#mainnav-mobi .flat-search,
#mainnav-mobi .social-links,
#mainnav-mobi .shopping-cart {
	display: none;
}

/* Button menu mobile */
.btn-menu {
	display: none;
	position: relative;
	background: transparent;
	cursor: pointer;
	margin: 40px 0;
	width: 26px;
	height: 16px;
	left: 48%;
    -webkit-transition: all ease .238s;
       -moz-transition: all ease .238s;
            transition: all ease .238s;
}

.btn-menu:before,
.btn-menu:after,
.btn-menu span {
	background-color: #fff;
    -webkit-transition: all ease .238s;
       -moz-transition: all ease .238s;
            transition: all ease .238s;
}

.btn-menu:before,
.btn-menu:after {
	content: '';
	position: absolute;
	top: 0;
	height: 2px;
	width: 100%;
	left: 0;
	top: 50%;
	-webkit-transform-origin: 50% 50%;
	    -ms-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
}

.btn-menu span {
	position: absolute;
	width: 100%;
	height: 2px;
	left: 0;
	top: 50%;
	overflow: hidden;
	text-indent: 200%;
}

.btn-menu:before {
	-webkit-transform: translate3d(0, -7px, 0);
	        transform: translate3d(0, -7px, 0);
}

.btn-menu:after {
	-webkit-transform: translate3d(0, 7px, 0);
            transform: translate3d(0, 7px, 0);
}

.btn-menu.active span {
	opacity: 0;
}

.btn-menu.active:before {
	-webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
}

.btn-menu.active:after {
	-webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
}

.btn-submenu {
   position: absolute;
   right: 20px;
   top: 0;
   font: 20px/50px 'FontAwesome';
   text-align: center;
   cursor: pointer;
   width: 70px;
   height: 44px;
}

.btn-submenu:before {
   content: "\f107";
   color: #fff;
}

.btn-submenu.active:before {
   content: "\f106"
}

.btn-menu {
   display: none
}

/* Shop card */
.menu-extra {
    float: right;
    margin-left: 30px;
}

.menu-extra ul > li {
    float: left;
}

.menu-extra ul li {
    list-style: none;
    display: inline-block;
    position: relative;
}

.menu-extra > ul > li > a {
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    padding: 18px 30px;
    font-size: 24px;
    display: block;
    color: #106a36;
}

.menu-extra > ul > li > a:hover {
	color: #ccc;
}

.menu-extra > ul > li:last-child > a {
    padding-right: 0;
}

.shopping-cart .subcart {
	position: absolute;
    width: 300px;
    right: 0;
    top: 150%;
    background-color: #ffffff;
    padding: 0 20px 20px 20px;
    z-index: -1;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       filter: alpha(opacity=0);
      opacity: 0;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
         -o-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all .2s ease-out;
       -moz-transition: all .2s ease-out;
       	-ms-transition: all .2s ease-out;
         -o-transition: all .2s ease-out;
           	transition: all .2s ease-out;
}

.shopping-cart:hover .subcart {
	top: 100%;
	visibility: visible;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
       filter: alpha(opacity=100);
      opacity: 100;
}

.shopping-cart .shopping-cart-items-count {
	position: absolute;
    background-color: #106a36;
    width: 24px;
    height: 24px;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    font-weight: 500;
    top: 15px;
    left: 45px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    z-index: 9;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
}

#mainnav .social-links {
	float: right;
	margin-top: 24px;
}

#mainnav .social-links a {
	width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background-color: rgba(255, 255, 255, 0.4);
    color: #000000;
    float: left;
    margin-left: 10px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

#mainnav .social-links a:hover {
	background-color: #106a36;
}

#mainnav .social-links a i {
    font-size: 18px;
}

/* Page title
-------------------------------------------------------------- */
.page-title {
	padding: 125px 0 45px 0;
	margin-bottom: 50px;
}

.page-title .page-title-heading h1.title {
	margin: 0 0 5px 0;
	color: #fff;
}

.page-title .breadcrumbs ul li {
	display: inline-block;
	color: #fff;
	margin-right: 10px;
}

.page-title .breadcrumbs ul li a {
	color: #fff;
	position: relative;
}

.page-title .breadcrumbs ul li a:before {
	position: absolute;
	left: 0;
	top: 100%;
	content: "";
	width: 0;
	height: 2px;
	background-color: #ccc;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		 -o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
}

.page-title .breadcrumbs ul li a:hover:before {
	width: 100%;
}

/* Blog
-------------------------------------------------------------- */
.main-content {
	margin-bottom: 50px;
}

.main-content p {
    margin-top: 0;
    margin-bottom: 20px;
}

.main-content p:last-child {
    margin-bottom: 0;
}

.main-content .content-wrap {
	padding-right: 15px;
}

.main-content-wrap article.post {
	margin-bottom: 60px;
}

.post .feature-post {
	margin-bottom: 30px;
}

.post .header-post .time-post {
	position: relative;
	margin-bottom: 10px;
	padding-left: 55px;
}

.post .header-post .time-post:after {
	width: 40px;
    height: 1px;
    content: "";
    position: absolute;
    left: 0;
    top: 15px;
    background: rgba(0, 0, 0, 0.1);
}

.post .header-post h2.title-post {
	margin-bottom: 20px;
}

.post .header-post h2.title-post a {
	color: #333333;
}

.post .header-post h2.title-post a:hover {
	color: #106a36;
}

.post .content-post {
	position: relative;
}

.post .content-post .meta-post {
	color: #777;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    margin-top: 27px;
}

.post .content-post .meta-post a {
	color: #333333;
}

.post .content-post .meta-post a:hover {
	color: #106a36;
}

.post .content-post .meta-post .comments-link {
	border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    padding-left: 20px;
}

.main-content.style .post .content-post .readmore a {
	padding: 10px 0;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #333333;
    margin-top: 30px;
}

.post .content-post .readmore a:hover {
	color: #106a36;
}

/* Blog single */
blockquote {
    margin: 30px 50px;
    position: relative;
    font-size: 18px;
    color: #333333;
}

/* Navigation */
.navigation.post-navigation .nav-links {
    padding: 0;
    margin: 0;
}

.navigation.post-navigation .nav-links li {
    list-style: none;
    display: inline-block;
    width: 49%;
    text-align: center;
}

.navigation.post-navigation .nav-links li a {
    color: #333333;
    display: block;
    padding: 15px;
    background: rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.navigation.post-navigation .nav-links li a:hover {
    background: #333333;
    color: #ffffff;
}

.navigation.post-navigation .nav-links li a span {
    color: #777;
}

.navigation.post-navigation .nav-links li a:hover span {
    color: #ffffff;
}

.navigation.post-navigation .nav-links li.next-post {
    float: right;
}

/* Comment form */
.comments-area {
	margin-bottom: 50px;
}

.comments-area .comment-reply-title {
    font-size: 24px;
    margin: 0 0 42px 0;
    position: relative;
}

.comments-area .comment-reply-title:before {
    background: rgba(0, 0, 0, 0.1);
    content: "";
    height: 1px;
    left: 50px;
    right: 0;
    position: absolute;
    bottom: -12px;
}

.comments-area .comment-reply-title:after {
	content: "";
    height: 3px;
    left: 0;
    width: 40px;
    position: absolute;
    bottom: -13px;
    background-color: #106a36;
}

.comments-area .comment-form p label {
	display: block;
}

.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p input[type="email"],
.comments-area .comment-form p input[type="url"] {
	width: 100%;
}

/* Widget
-------------------------------------------------------------- */
.sidebars {
	padding-left: 15px;
}

.widget {
    margin-bottom: 60px;
}

.widget:last-child {
    margin-bottom: 0;
}

.widget .widget-title {
    font-size: 24px;
    margin-bottom: 35px;
    margin-top: 0;
    padding: 0 15px 0 0;
    position: relative;
}

.widget .widget-title:before {
    background: rgba(0, 0, 0, 0.1);
    content: "";
    height: 1px;
    left: 50px;
    right: 0;
    position: absolute;
    bottom: -12px;
}

.widget .widget-title:after {
    content: "";
    height: 3px;
    left: 0;
    width: 40px;
    position: absolute;
    bottom: -13px;
    background-color: #106a36;
}

.widget ul li:first-child {
    padding-top: 0;
}

.widget ul li {
    list-style: none;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
    padding: 10px 0;
}

.widget ul li:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.widget ul li a {
    color: #777;
}

.widget ul li a:hover {
    color: #000000;
}

/* Widget search */
.widget.widget_search .search-form {
    position: relative;
}

.widget.widget_search .search-form .search-field {
	width: 100%;
}

.widget.widget_search .search-form .search-submit {
	background-image: url(/assets/images/icon/arrow.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 11px;
    padding: 0;
    position: absolute;
    background-color: transparent;
    right: 0;
    top: 0;
    -webkit-opacity: 0.3;
    -khtml-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
    filter: alpha(opacity=30);
}

.widget.widget_search .search-form .search-submit:hover {
	background-color: transparent;
	-webkit-opacity: 1;
    -khtml-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
    filter: alpha(opacity=100);
}

.widget.widget_search .search-form input[type="submit"] {
	color: #ffffff;
    height: 45px;
    line-height: 45px;
    padding: 0 40px;
    cursor: pointer;
    white-space: nowrap;
    border: none;
    display: inline-block;
    font-weight: bold;
    font-size: 0;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
            border-radius: 2px;
}

/* Widget categories */
.widget.widget_categories ul li {
    color: #999;
}

/* Widget tags */
.widget.widget_tag_cloud .tagcloud {
    overflow: hidden;
}

.widget.widget_tag_cloud .tagcloud a {
    color: #cccccc;
    background: #333333;
    height: 30px;
    line-height: 30px;
    padding: 0 25px;
    margin: 0 5px 5px 0;
    float: left;
    position: relative;
    font-size: 1em !important;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
        -ms-border-radius: 50px;
         -o-border-radius: 50px;
            border-radius: 50px;
}

.widget.widget_tag_cloud .tagcloud a:hover {
	color: #fff;
}

/* Content bottom widget */
.content-bottom-widgets {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30px 0;
}

.content-bottom-widgets .widget.widget_text .textwidget {
	padding: 10px 0;
	position: relative;
}

.content-bottom-widgets .widget.widget_text .textwidget:after {
	position: absolute;
	right: -15px;
	top: 0;
	content: "";
	width: 1px;
	height: 80px;
	background-color: rgba(0, 0, 0, 0.1);
}

.content-bottom-widgets .subscrbe-form .sub-form {
    position: relative;
}

.content-bottom-widgets .subscrbe-form {
	padding-left: 50px;
}

.content-bottom-widgets .subscrbe-form .sub-form .subscrbe-field {
	width: 100%;
}

.subscrbe-form .sub-form .subscrbe-submit {
	background-image: url(/assets/images/icon/arrow.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 11px;
    padding: 0;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-opacity: 0.3;
    -khtml-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
    filter: alpha(opacity=30);
}

.subscrbe-form .sub-form .subscrbe-submit:hover {
	background-color: transparent;
	-webkit-opacity: 1;
    -khtml-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
    filter: alpha(opacity=100);
}

.subscrbe-form .sub-form input[type="submit"] {
	color: #ffffff;
    height: 45px;
    line-height: 45px;
    padding: 0 40px;
    cursor: pointer;
    white-space: nowrap;
    border: none;
    display: inline-block;
    font-weight: bold;
    font-size: 0;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
            border-radius: 2px;
}

/* Widget widget_shopping_cart_content */
.widget_shopping_cart_content .cart_list li {
    display: inline-block;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}

.widget_shopping_cart_content .cart_list li a {
    padding: 0;
    color: #777;
    font-weight: 600;
}

.widget_shopping_cart_content .cart_list li a:hover {
	color: #333333;
}

.widget_shopping_cart_content .cart_list li .remove {
    color: #cccccc;
    float: right;
    height: 24px;
    line-height: 22px;
    text-align: center;
    width: 24px;
    margin-top: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.widget_shopping_cart_content .cart_list li a img {
    max-width: 55px;
    height: auto;
    float: left;
    margin-right: 15px;
}

.widget_shopping_cart_content .total {
    padding-top: 10px;
    color: #333333;
    border-top: 1px dotted rgba(0, 0, 0, 0.1);
}

.widget_shopping_cart_content .flat-button.s1 {
	float: left;
}

.widget_shopping_cart_content .flat-button.s2 {
	float: right;
	margin-right: 20px;
}

.widget_shopping_cart_content .flat-button.s2 a {
	background: transparent;
	color: #333333;
	border-bottom: 2px solid #333333;
	padding: 0;
	-webkit-border-radius: 0;
       -moz-border-radius: 0;
        -ms-border-radius: 0;
         -o-border-radius: 0;
    		border-radius: 0;
}

/* Boxed
-------------------------------------------------------------- */
.boxed {
    overflow-x: hidden;
}

.home-boxed .boxed {
	width: 90%;
	margin: 0 auto;
	position: relative;
	background: #fff;
	height: 100%;
  	-webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.02);
  	   -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.02);
  	        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.02);
}

/* Switcher
-------------------------------------------------------------- */
.switcher-container {
   position: fixed;
   right: -290px;
   top: 80px;
   width: 290px;
   background-color: #000;
   z-index: 99999999;
}

.switcher-container h2 {
	color: #fff;
	font-size: 15px;
	font-weight: 400;
	font-family: "Playfair Display", sans-serif;
	letter-spacing: 1.5px;
    text-transform: uppercase;
	height: 50px;
	line-height: 50px;
	padding-left: 50px;
	padding-right: 85px;
	margin: 0;
	font-weight: 400;
}

.switcher-container h2 a {
   background-color: #000;
   display: block;
   position: absolute;
   left: -50px;
   top: 0;
   width: 50px;
   height: 50px;
   line-height: 50px;
   text-align: center;
   outline: 0;
   color: #fff;
}

.switcher-container h2 a:hover,
.switcher-container h2 a:focus {
   text-decoration: none;
}

.switcher-container h2 i {
   margin-top: 12px;
   font-size: 25px;
   -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
}

.switcher-container h3 {
   font-size: 14px;
   font-weight: 700;
   color: #fff;
   margin: 0;
   line-height: 22px;
   margin-bottom: 10px;
   font-size: 16px;
   line-height: 30px;
   font-weight: 400;
}

.switcher-container .selector-box {
   color: #fff;
   overflow: hidden;
}

.switcher-container .layout-switcher {
   margin: 0 0 10px 0;
   overflow: hidden;
}

.switcher-container .layout-switcher a.layout {
   float: left;
   display: block;
   cursor: pointer;
   text-align: center;
   font-weight: 700;
   padding: 10px 20px;
   margin-left: 10px;
}

.switcher-container .layout-switcher a.layout:first-child {
   margin-left: 0;
}

.switcher-container .layout-switcher a.layout:hover {
   color: #fff;
   cursor: pointer;
}

.switcher-container .color-box {
   height: auto;
   overflow: hidden;
   margin-bottom: 6px;
}

.switcher-container .styleswitch {
    margin-bottom: 10px;
}

.sw-odd {
	background-color: #272727;
	padding: 21px 0 30px 50px;
	-webkit-transition: all 0.3s;
	   -moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		 -o-transition: all 0.3s;
			transition: all 0.3s;
}

.sw-even {
	background-color: #191919;
	padding: 21px 0 25px 50px;
	-webkit-transition: all 0.3s;
	   -moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		 -o-transition: all 0.3s;
			transition: all 0.3s;
}

.sw-even a {
	font-family: "Karla", sans-serif;
    text-transform: uppercase;
	font-size: 12px;
	line-height: 40px;
	color: #fff;
	border: 1px solid #fff;
	padding: 10px 26px 10px 27px;
	margin-right: 10px;
	letter-spacing: 1.8px;
}

.sw-even a:hover {
	background-color: #106a36;
	border: 1px solid #106a36;
	color: #fff !important;
}

.sw-light {
	background-color: #fff;
	color: #000 !important;
}

.sw-odd a {
	font-size: 16px;
	color: #fff;
	width: 100%;
	display: inline-block;
	line-height: 17px;
	width: 100%;
	position: relative;
	padding-left: 47px;
}

.sw-odd .ws-colors a:before {
	background: none;
}

.sw-odd .ws-colors a {
	position: relative;
	width: auto;
	padding: 0;
	width: 30px;
	height: 30px;
	background-color: #106a36;
	display: inline-block;
	margin-right: 15px;
}

.sw-odd .ws-colors a.current:before {
	position: absolute;
    left: 8px;
    top: 6px;
	font-family: FontAwesome;
	content: "\f00c";
	color: #fff;
	text-shadow: 0 0 2px rgba( 0, 0, 0, 1 );
}

.sw-odd #color2 {
	background-color: #f0c600;
}

.sw-odd #color3 {
	background-color: #e948ae;
}

.sw-odd #color4 {
	background-color: #0088cc;
}

.sw-odd #color5 {
	background-color: #f99373;
}

.sw-even h3 {
	margin-bottom: 6px;
}

/* Pattern */
.sw-pattern.pattern {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	   	filter: alpha(opacity=0);
	   opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
	position: absolute;
	left: 0;
	top: 98%;
	background-color: #000000;
	width: 100%;
	z-index: -1;
	padding: 21px 0 30px 50px;
}

.sw-pattern.pattern a {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
}

.sw-pattern.pattern a.current:before {
	position: absolute;
    left: 12px;
    top: 6px;
	font-family: FontAwesome;
	content: "\f00c";
	color: #fff;
	text-shadow: 0 0 2px rgba( 0, 0, 0, 1 );
}

/* GoTop Button
-------------------------------------------------------------- */
.go-top {
   	position: fixed !important;
   	right: -35px;
	bottom: 15px;
   	width: 32px;
   	height: 32px;
   	-webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
    		border-radius: 2px;
   	cursor: pointer;
    background-color: #106a36;
   	line-height: 32px;
   	text-align: center;
   	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
   	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	   	filter: alpha(opacity=0);
	  	opacity: 0;

}

.go-top.show {
	right: 15px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
	visibility: visible;
}

.go-top i {
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	padding-bottom: 3px;
}

.go-top:hover {
    background-color: #333;
}

.go-top:hover i {
	color: #fff;
}

/* Footer
-------------------------------------------------------------- */
.footer {
    background-color: #2a2a2a;
    background-position: top left;
    background-repeat: repeat;
    background-attachment: scroll;
    color: #777;
}

.footer .footer-widgets {
    padding-top: 52px;
    padding-bottom: 40px;
}

.footer .footer-widgets .widget .widget-title {
    color: #ffffff;
}

.footer .footer-widgets .widget .widget-title:before {
    background: rgba(255, 255, 255, 0.1);
}

.footer .footer-widgets .widget ul li {
    border-color: rgba(255, 255, 255, 0.1);
}

.footer .footer-widgets .widget a {
    color: #cccccc;
}

/* Widget text */
.widget.widget_text .textwidget p {
	margin-bottom: 20px;
}

/* Widget text style1 */
.widget.widget_text.style1 {
	background-color: #106a36;
	padding: 20px;
}

.widget.widget_text.style1 .textwidget p {
	color: #fff;
}

.widget.widget_text.style1 .textwidget p a:hover {
	color: #fff;
}

/* Footer widget text */
.footer .footer-widgets .widget .button {
    color: #ffffff;
    height: 50px;
    line-height: 46px;
    border: 2px solid transparent;
}

.footer .footer-widgets .widget .button:hover {
	background: transparent;
	border: 2px solid #fff;
}

/* Footer widget nav menu */
.footer .footer-widgets .widget.widget_nav_menu ul li {
    border: none;
    padding: 0;
    margin-bottom: 5px;
}

.footer .footer-widgets .widget.widget_nav_menu ul li a:hover {
    color: #ffffff;
}

/* Footer widget recent entries */
.widget.widget_recent_entries .post-date {
    display: block;
    position: relative;
}

.widget.widget_recent_entries .post-date:before {
    content: "\f041";
    font-family: "FontAwesome";
    font-size: 14px;
    margin-right: 10px;
}

/* Footer content */
.footer .footer-content {
    background: #fff;
    padding: 5px 0;
	.copyright-content {
		padding-top: 2px;
	}
}

/* Social
-------------------------------------------------------------- */
.footer .social-links {
    float: right;
    margin-top: 0px;
}

.footer .social-links a {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    float: left;
    margin-left: 10px;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
    		border-radius: 2px;
}

.footer .social-links a:hover {
    color: #333333;
}

.footer .social-links a {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    float: left;
    margin-left: 10px;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
            border-radius: 2px;
}

.footer .social-links a:hover {
	background-color: #106a36;
}

.footer .copyright {
	float: left;
}

/* Flat search
-------------------------------------------------------------- */
.top-search {
    position: absolute;
    width: 300px;
    right: 0;
    top: 100px;
    opacity: 0;
    z-index: -99;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.top-search input[type="search"] {
	background-color: #fff;
}

.top-search.show {
    top: 80px;
    opacity: 1;
    z-index: 9999;
}

/* Search results
-------------------------------------------------------------- */
.flat-searchs-results {
	padding-right: 15px;
}

.flat-searchs-results .subscrbe-form .sub-form .subscrbe-submit {
	right: 30px;
}

.flat-search-results {
	padding: 0 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 30px 0 0 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

.flat-search-results .search-results article {
	padding: 30px 0 30px 65px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: inherit;
    display: block;
}

.flat-search-results .search-results article:last-child {
	border: none;
}

.flat-search-results .search-results article .counter {
	background-color: #106a36;
	position: absolute;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    top: 50%;
    margin-top: -25px;
    left: 0;
    font-size: 1.5em;
    color: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
         -o-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    		box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
        -ms-border-radius: 2px;
         -o-border-radius: 2px;
    		border-radius: 2px;
}

.flat-search-results .search-results article .entry-title {
	font-size: 24px;
    margin: 0 0 5px 0;
}

.flat-search-results .search-results article .entry-title a {
	color: #333;
}

/* 404
-------------------------------------------------------------- */
.error404 {
	padding-top: 50px;
}

.error404 #main-content .content-inner {
  	text-align: center;
}

.error404 #main-content .content-inner .heading-404 {
  	padding: 150px 0 0 100px;
  	display: inline-block;
  	background-color: #106a36;
}

.error404 #main-content .content-inner .content-404 h2 {
  	margin-bottom: 10px;
  	margin-top: 30px;
}

.error404 #main-content .content-inner .content-404 .subscrbe-form {
  	position: relative;
  	width: 50%;
  	margin: 0 auto 50px;
}

/* Parallax
-------------------------------------------------------------- */
.parallax {
	width: 100%;
	// background-attachment: fixed;
	background-position: center center;
	background-size: cover;
}

.header-default { background-image: url(/assets/images/header/default.jpg); }
.header-about { background-image: url(/assets/images/header/about.jpg); }
.header-contact { background-image: url(/assets/images/header/contact.jpg); }
.header-services { background-image: url(/assets/images/header/services.jpg); }
.header-multifamily { background-image: url(/assets/images/header/multifamily.jpg); }
.header-assisted-living-facility { background-image: url(/assets/images/header/assisted-living-facility.jpg); }
.header-commercial-light-industrial { background-image: url(/assets/images/header/commercial-light-industrial.jpg); }
.header-projects { background-image: url(/assets/images/header/projects.jpg); }

.bg-contact {
	background-image: url(/assets/images/parallax/bg-parallax5.jpg);
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-playvideo { background-image: url(/assets/images/parallax/bg-parallax2.jpg); }
.bg-playvideo { background-size: cover; }

.parallax4 { background-image: url(/assets/images/parallax/bg-parallax4.jpg); }
.parallax5 { background-image: url(/assets/images/parallax/bg-parallax5.jpg); }
.parallax7 { background-image: url(/assets/images/parallax/bg-parallax7.jpg); }
.parallax8 { background-image: url(/assets/images/parallax/bg-parallax8.jpg); }

/* Revolution Slider
-------------------------------------------------------------- */
.tp-banner-container{
	max-height: 456px !important;
	width: 100% !important;
	position: relative;
	overflow: hidden !important;
	padding: 0;
	background-color: #fff;
}

.tp-bullets.simplebullets.navbar {
	height: 35px;
	padding: 0px 0px;
}

.tp-bullets.simplebullets .bullet {
	cursor: pointer;
	position: relative !important;
	background: #fff !important;
	width: 16px !important;
	height: 16px !important;
	display: inline-block;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
       filter: alpha(opacity=60);
      opacity: 0.6;
	-webkit-box-shadow: none;
	   -moz-box-shadow: none;
			box-shadow: none;
	margin-right: 5px !important;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	     -o-border-radius: 50%;
	    -ms-border-radius: 50%;
			border-radius: 50%;
	-webkit-transition: background-color 0.2s, border-color 0.2s;
	   -moz-transition: background-color 0.2s, border-color 0.2s;
	     -o-transition: background-color 0.2s, border-color 0.2s;
	    -ms-transition: background-color 0.2s, border-color 0.2s;
			transition: background-color 0.2s, border-color 0.2s;
	float:none !important;
}

.tp-bullets.simplebullets .bullet.last {
	margin-right: 0px
}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	opacity: 1;
	background: #fff !important;
	width: 16px !important;
	height: 16px !important;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	     -o-border-radius: 50%;
	    -ms-border-radius: 50%;
			border-radius: 50%;
}

/* Preload
-------------------------------------------------------------- */
.loading-overlay {
    background: #FFF;
    color: #424242;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
}

.loader, .loader:after {
    width: 30px;
    height: 30px;
}

.loader {
    margin: 20% auto;
    border-radius: 50%;
    font-size: 6px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(194, 193, 193, 0.5);
    border-right: 1.1em solid rgba(199, 197, 197, 0.5);
    border-bottom: 1.1em solid rgba(203, 203, 203, 0.5);
    border-left: 1em solid #106a36;
    -webkit-animation: fa-spin 2s infinite linear;
           animation: fa-spin 2s infinite linear;
}

@-moz-keyframes bounceG{
  0% {
  -moz-transform:scale(1.2);
  opacity:1
  }

  100%{
    -moz-transform:scale(0.7);
    opacity:0.1
  }
}

@-webkit-keyframes bounceG{
  0% {
    -webkit-transform:scale(1.2);
    opacity:1
  }

  100% {
    -webkit-transform:scale(0.7);
    opacity:0.1
  }
}

@-ms-keyframes bounceG{
  0% {
    -ms-transform:scale(1.2);
    opacity:1
  }

  100% {
    -ms-transform:scale(0.7);
    opacity:0.1
  }
}

@-o-keyframes bounceG{
  0% {
    -o-transform:scale(1.2);
    opacity:1
  }

  100%{
    -o-transform:scale(0.7);
    opacity:0.1
  }
}

@keyframes bounceG{
  0% {
    transform:scale(1.2);
    opacity:1
  }

  100% {
    transform:scale(0.7);
    opacity:0.1
  }
}
